import React from "react";

import * as S from "./Accordion.styles";

export type SectionHeaderProps = {
  children: React.ReactNode;
  onClear?: () => void;
  disabled?: boolean;
  shouldDisableDivider?: boolean;
  triggerSibling?: React.ReactNode;
};

const SectionHeader = ({
  children,
  onClear,
  disabled,
  shouldDisableDivider,
  triggerSibling
}: SectionHeaderProps) => {
  return (
    <S.Header>
      <S.Title>{children}</S.Title>
      {!shouldDisableDivider && <S.Divider />}
      {onClear && (
        <S.ClearButton analyticId="clear" onClick={() => onClear()}>
          Clear
        </S.ClearButton>
      )}
      <S.TriggerContainer>
        {triggerSibling && triggerSibling}
        <S.Trigger>{!disabled && <S.Chevron />}</S.Trigger>
      </S.TriggerContainer>
    </S.Header>
  );
};

export default SectionHeader;
